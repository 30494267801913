import axios, { AxiosResponse } from "axios";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let Constants: any = {};

export async function setConstants() {
	const response: AxiosResponse = await axios.get(
		(process.env.REACT_APP_XPRESS_URL ?? "") + "/config",
	);
	Constants = response.data;
}
